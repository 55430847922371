
import { Component, Vue } from 'nuxt-property-decorator'
import Banner from '~/components/Banner.vue'
import ScareBlockGibdd from '~/components/info-block/gibdd/ScareBlockGibdd.vue'
import TrustBlockGibdd from '~/components/info-block/gibdd/TrustBlockGibdd.vue'
import SaleBlockGibdd from '~/components/info-block/gibdd/SaleBlockGibdd.vue'
import FineInfoBlockGibdd from '~/components/info-block/gibdd/FineInfoBlockGibdd.vue'
import ExampleGibdd from '~/components/info-block/gibdd/ExampleGibdd.vue'
import ActualDataBlockGibdd from '~/components/info-block/gibdd/ActualDataBlockGibdd.vue'
import NotifyBlockGibdd from '~/components/info-block/gibdd/NotifyBlockGibdd.vue'
import GuaranteeBlockGibdd from '~/components/info-block/gibdd/GuaranteeBlockGibdd.vue'
import SafetyBlockGibdd from '~/components/info-block/gibdd/SafetyBlockGibdd.vue'
import SupportBlockGibdd from '~/components/info-block/gibdd/SupportBlockGibdd.vue'
import FullInfoGibdd from '~/components/info-block/gibdd/FullInfoGibdd.vue'
import AdvantagesGibdd from '~/components/info-block/gibdd/AdvantagesGibdd.vue'
import AdvantagesGibddMobile from '~/components/info-block/gibdd/AdvantagesGibddMobile.vue'

@Component({
  components: {
  SupportBlockGibdd,
  SafetyBlockGibdd,
  GuaranteeBlockGibdd,
  NotifyBlockGibdd,
  ActualDataBlockGibdd,
  ExampleGibdd,
  FineInfoBlockGibdd,
  SaleBlockGibdd,
  TrustBlockGibdd,
  ScareBlockGibdd,
  Banner,
  FullInfoGibdd,
  AdvantagesGibdd,
  AdvantagesGibddMobile
  }
  })
export default class extends Vue {
}
